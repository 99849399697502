.liked-users-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal-content {
        background: white;
        padding: 20px;
        border-radius: 8px;
        width: 300px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        text-align: center;

        h3 {
            margin-bottom: 15px;
        }

        .liked-users-list {
            list-style: none;
            padding: 0;
            margin: 0;

            .liked-user {
                display: flex;
                align-items: center;
                margin: 10px 0;

                .user-avatar {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .user-login {
                    font-weight: bold;
                }
            }
        }

        .close-button {
            margin-top: 15px;
            color: white;
            border: none;
            border-radius: 5px;
            padding: 10px;
            cursor: pointer;
            transition: background 0.3s;

            &:hover {
                background: #e6006e;
            }
        }
    }
}
