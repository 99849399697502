.UserProfile{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    top: 50px;

    .userProfile-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 20px;

        .userProfileHeader-block1{
            nav{
                font-size: 21px;
                font-weight: 600;
            }
        }

        .userProfileHeader-block2{
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;

            .userProfileHeader-block2_logoImage{
                img{
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                }
            }

            .userProfileHeader-block2_countInfo{
                ul{
                    display: flex;
                    gap: 20px;

                    li{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        cursor: pointer;
                        font-size: 18px;
                    }
                }
            }

            .userProfileHeader-block2_subscribe{
                button{
                    background: none;
                    width: 100%;
                    cursor: pointer;
                    padding: 2px;
                    margin-top: 5px;
                    border: 0.5px solid black;
                    color: black;
                }
            }
            
        }

        .userProfileHeader-block3{
            display: flex;
            flex-direction: column;

            .userProfileHeader-block3_userName{
                font-weight: 700;
            }
        }
    }

    .userProfile-footer{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 100px;
        justify-content: start;
        gap: 10px;

        img, video{
            width: 300px;
            height: 300px;
            cursor: pointer;
            object-fit: contain; /* 'contain' полностью поместит изображение в контейнер, но могут остаться пустые области */
            object-position: center;
            background-color: #000;
            border: 0.5px solid rgb(98, 98, 98);
        }
    }
}

@media (max-width: 950px){
    .UserProfile{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        top: 150px;
    
        .userProfile-header{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            gap: 10px;

            .userProfileHeader-block1{
                nav{
                    font-size: 17px;
                    font-weight: 600;
                }
            }

            .userProfileHeader-block2{
                display: flex;
                gap: 5px;
                justify-content: center;
                align-items: center;

                .userProfileHeader-block2_logoImage{
                    img{
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                    }
                }

                .userProfileHeader-block2_countInfo{
                    ul{
                        display: flex;
                        gap: 5px;

                        li{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            cursor: pointer;
                            font-size: 13px;
                        }
                    }
                }

                .userProfileHeader-block2_subscribe{
                    button{
                        background: none;
                        width: 100%;
                        cursor: pointer;
                        padding: 2px;
                        margin-top: 5px;
                        border: 0.5px solid black;
                        color: black;
                    }
                }
                
            }

            .userProfileHeader-block3{
                display: flex;
                flex-direction: column;

                .userProfileHeader-block3_userName{
                    font-weight: 700;
                }
            }
        }
    
        .userProfile-footer{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 100px;
            justify-content: start;
            gap: 5px;
    
            img, video{
                width: 100%;
                height: 300px;
                cursor: pointer;
                border: 0.5px solid rgb(98, 98, 98);
            }
        }
    }
}

@media (max-width: 480px){
    .UserProfile{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 20px;
    
        .userProfile-header{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            gap: 10px;

            .userProfileHeader-block1{
                nav{
                    font-size: 17px;
                    font-weight: 600;
                }
            }

            .userProfileHeader-block2{
                display: flex;
                gap: 5px;
                justify-content: center;
                align-items: center;

                .userProfileHeader-block2_logoImage{
                    img{
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                    }
                }

                .userProfileHeader-block2_countInfo{
                    ul{
                        display: flex;
                        gap: 5px;

                        li{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            cursor: pointer;
                            font-size: 13px;
                        }
                    }
                }

                .userProfileHeader-block2_subscribe{
                    button{
                        background: none;
                        width: 100%;
                        cursor: pointer;
                        padding: 2px;
                        margin-top: 5px;
                        border: 0.5px solid black;
                        color: black;
                    }
                }
                
            }

            .userProfileHeader-block3{
                display: flex;
                flex-direction: column;

                .userProfileHeader-block3_userName{
                    font-weight: 700;
                }
            }
        }
    
        .userProfile-footer{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 100px;
            justify-content: start;
            gap: 2px;
    
            img, video{
                width: 100%;
                height: 150px;
                cursor: pointer;
                border: 0.5px solid rgb(98, 98, 98);
            }
        }
    }
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1250;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.Modal h2 {
    margin-top: 0;
}

.Modal ul {
    list-style: none;
    padding: 0;
}

.Modal ul li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.Modal ul li img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.Modal ul li span {
    font-size: 16px;
}

.Modal button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.Modal button:hover {
    background: #0056b3;
}