.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh; // Компонент будет занимать всю высоту окна
    background-color: #fafafa; // Светло-серый фон, как у Instagram
}

.auth-form-container {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    padding: 40px 30px;
    text-align: center;
    border-radius: 1px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    width: 100%;

    .auth-logo {
        margin-bottom: 20px;

        img {
            width: 175px; // Размер логотипа
        }
    }

    .auth-inputs {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        input {
            width: 100%;
            padding: 9px;
            font-size: 14px;
            border: 1px solid #dbdbdb;
            border-radius: 3px;
            background-color: #fafafa;
            outline: none;

            &:focus {
                border-color: #a8a8a8;
            }
        }
    }

    .auth-button {
        width: 100%;
        padding: 8px;
        font-size: 14px;
        background-color: #0095f6;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        font-weight: 600;
        margin-top: 10px;

        &:hover {
            background-color: #0084e6;
        }
    }

    .auth-link-container {
        margin-top: 20px;

        .auth-link {
            color: #0095f6;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
