.story-list {
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: auto; /* Горизонтальная прокрутка */
  padding: 10px;
  gap: 15px; /* Расстояние между элементами */
  background-color: #fff;

  /* Скрытие полосы прокрутки */
  &::-webkit-scrollbar {
    display: none;
  }
}

.story-item {
  position: relative;
  display: flex;
  flex-direction: column; /* Выравнивание текста под аватаром */
  align-items: center;
  width: 70px;
  height: 90px;
  text-align: center;
  cursor: pointer;

  img {
    width: 65px;
    height: 65px;
    border-radius: 50%; /* Круглый аватар */
    border: 3px solid transparent; /* Граница для истории */
    object-fit: cover;
    transition: transform 0.3s ease;

    &.viewed {
      border: 2px solid gray; // Обводка для просмотренных сторисов
    }
  
    &.not-viewed {
      border: 2px solid darkblue; // Обводка для непросмотренных сторисов
    }

    &.new-story {
      border-color: #f09433; /* Градиентная обводка (эмуляция) */
      background-image: linear-gradient(
        to right,
        #f09433,
        #e6683c,
        #dc2743,
        #cc2366,
        #bc1888
      );
      background-clip: border-box;
    }
  }

  span {
    margin-top: 5px;
    font-size: 12px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover img {
    transform: scale(1.1); /* Увеличение при наведении */
  }
}

/* Новый контейнер для аватара и кнопки */
.avatar-container {
  position: relative;
  width: 65px;
  height: 65px; /* Размер контейнера */
}

.avatar-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
}

.add-story1 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: white;
  border: 2px solid #000;
  border-radius: 50%;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.add-story:hover {
  background-color: #f0f0f0;
}

.add-story-button {
  position: absolute;
  width: 65px;
  height: 65px;
  border: 2px dashed #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #666;
  background: #fafafa;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #007bff;
    border-color: #007bff;
  }
}

.add-story span {
  margin-top: 5px;
  font-size: 12px;
  color: #666;
}

/* Стили для модального окна */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}
