.story-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1500;

  .story-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;

    .user-info {
      display: flex;
      align-items: center;
      z-index: 1501;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .login {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .close-btn {
      z-index: 1501;
      font-size: 20px;
      background: none;
      color: white;
      border: none;
      cursor: pointer;
    }
  }

  .progress-bar {
    display: flex;
    width: 100%;
    height: 4px;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    margin: 10px 0;
  
    .progress-segment {
      flex-grow: 1;
      position: relative;
      height: 100%;
      margin-right: 2px;
  
      &:last-child {
        margin-right: 0;
      }
  
      .progress-fill {
        height: 100%;
        transition: width 0.1s linear;
      }
    }
  
    .active .progress-fill {
      background: white;
    }
  }  

  .story-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .story-media {
      max-width: 900px;
      max-height: 900px;
      width: 100%;
      height: 100%;
      object-fit: contain; /* Ограничиваем размеры */
    }
  }

  .story-nav {
    position: absolute;
    top: 50%;
    width: 50%;
    height: 100%;
    transform: translateY(-50%);
    cursor: pointer;

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }

  .story-text {
    padding: 20px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
  }
}
