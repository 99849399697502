.header{
    position: sticky;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1050;

    .header-line{
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ddd;
    }

    .header-logo{
        display: flex;
        gap: 5px;
        cursor: pointer;
        align-items: center;

        span{
            background-color: black;
            color: #fff;
            font-weight: 500;
            font-size: 20px;
            padding: 5px;
            border-radius: 5px;
        }
        nav{
            font-weight: 500;
            font-size: 20px;
        }
    }

    .header-search{
        display: flex;
        img{
            cursor: pointer;
        }

        form{
            display: flex;
            gap: 10px;

            input{
                font-size: 20px;
                padding: 5px;
            }
            button{
                border: 0;
                background: none;
                cursor: pointer;
            }
        }
    }

    .header-menu{
        display: flex;
        vertical-align: middle;
        align-items: center;
        gap: 20px;
        z-index: 1040;
        
        span{
            cursor: pointer;;

            img{
                width: 40px;
                height: 40px;
                background-color: none;
            }
        }
        .header-menuAuth{
            font-size: 20px;
            font-weight: 500;
            vertical-align: middle;
        }
        nav{
            vertical-align: middle;
            cursor: pointer;
            img{
                width: 50px;
                height: 50px;
                border-radius: 50px;
            }
        }
        .header-ul{
            display: none;
            position: absolute;
            opacity: 0;
        }
        .active-menu{
            display: block;
            position: absolute;
            top: 100%;
            background-color: #f3f3f3;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            right: 0px;
            flex-direction: column;
            justify-content: start;
            opacity: 1;

            .header-li{
                cursor: pointer;
                font-size: 18px;
                font-weight: 400;
                padding: 10px 40px 10px 10px;
                background-color: #fff;
                transition: 0.7s;
                display: flex;
                align-items: center;
                gap: 5px;

                /* src/styles.css */
                .notification-dot {
                    height: 10px;
                    width: 10px;
                    background-color: yellow;
                    border-radius: 50%;
                    display: inline-block;
                    margin-left: 5px;
                }


                img{
                    width: 25px;
                }
                button{
                    border: none;
                    background-color: none;
                    background: none;
                    color: red;
                    cursor: pointer;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
            .deleteAcc{
                color: red;
            }
            .header-li:hover{
                background-color: #f3f3f3;
                transition: .7s;
            }
        }
    }
}

.header-search {
    position: relative;
}

.suggestions-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 25px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.suggestions-list li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.suggestions-list li img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.suggestions-list li:hover {
    background-color: #f0f0f0;
}

.search-modal{
    position: absolute;
    right: 10px;
    top: 50px;
}

@media(max-width: 480px) {
    .header{
        .header-menu{
            gap: 10px;
        }

        .header-search{
            .search-modal{
                left: -150px;
            }
        }
    }
}