.postPage {
    display: flex;
    flex-direction: column;
    width: 920px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

    .postPage-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #efefef;

        .postHeader-login {
            display: flex;
            align-items: center;
            gap: 10px;

            .postHeader-loginContainer {
                background-color: inherit;
                display: inline-block;
                img {
                    display: block;
                    width: 50px;
                    height: 50px;
                    background-color: #efefef;
                    padding: 5px;
                    border-radius: 50%;
                }
            }
            span {
                font-size: 20px;
                font-weight: 500;
            }
        }

        .postHeader-nav {
            button {
                background-color: #5b4df7;
                color: #fff;
                font-size: 12px;
                padding: 10px 22px;
                border: none;
                border-radius: 10px;
                margin-left: 10px;
            }
        }
    }

    .postPage-main {
        img {
            width: 100%;
            height: 100%;
            max-height: 650px;
            object-fit: contain;
            object-position: center;
            background-color: #000;
        }
        
        video {
            width: 100%;
            height: 100%;
            max-height: 650px;
            background-color: #000;
            object-fit: contain;
            object-position: center;
        }
    }

    .postPage-footer {
        padding: 0;
        background-color: #efefef;
        width: 100%;

        .pageFooter-countsInf {
            display: flex;
            gap: 20px;
            align-items: center;
            padding: 5px;

            nav {
                span {
                    font-size: 18px;
                    font-weight: 400;
                }
                .buttonLike {
                    width: 30px;
                    height: 28px;
                }
            }
            img {
                width: 30px;
                height: 28px;
                cursor: pointer;
            }
        }

        .pageFooter-underText {
            margin-top: 5px;
            font-size: 2vh;
            font-weight: 400;
            width: 60%;

            p {
                padding-left: 5px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                font-size: 18px;
            }

            .postDate {
                margin-top: 5px;
                font-size: 1.5vh;
                color: #888;
            }
        }
    }
}

.custom-video {
    position: relative;
    width: 100%;
    background-color: #000;

    .video-controls {
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: flex;
        gap: 10px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        button {
            background-color: rgba(0, 0, 0, 0.5);
            border: none;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 5px;
        }
    }

    .video-controls.visible {
        visibility: visible;
        opacity: 1;
    }
}

.liked-users {
    font-size: 14px;
    margin-top: 5px;
    color: #555;
    
    a {
        text-decoration: none;
        color: inherit;
        font-weight: bold;
    }
    
    span {
        cursor: pointer;
    }
}


@media (max-width: 1000px) {
    .postPage {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .postPage {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .postPage {
        .postPage-main {
            img, video {
                height: 100%;
                max-height: 550px;
            }
        }
    }
}
