.comment-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* затемнение фона */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1300; /* поверх всего контента */
}

.comment-modal-content {
    background-color: #fff;
    padding: 5px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    max-height: 80%;
    overflow-y: auto;
}

.close-button {
    color: #fff;
    background: none;
    border: none;
    font-size: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.comments-section {
    margin-top: 20px;
}

.comments-list {
    max-height: 300px;
    overflow-y: auto;
}

.comment {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .commentMain{
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .commentContent{
        display: flex;
        flex-direction: column;
    }

    .deleteComment{
        img{
            cursor: pointer;
        }

        .active-menu {
            display: block;
            
        }
    
        .unActive {
            display: none;
        }
    }

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    scrollbar-width: none;

    ul {
        position: absolute;
        top: 100%; /* Спускаем меню ниже изображения */
        right: 0;  /* Выравниваем меню по правой стороне */
        background-color: #f3f3f3;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        flex-direction: column;
        justify-content: start;

        li {
            color: black;
            background-color: #EFEFEF;
            padding: 20px;
            cursor: pointer;    
        }

        .deletePostButton {
            color: red;
            font-size: 12px;
        }

        li:hover {
            background-color: gray;
        }
    }

    .commentMain{
        img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }
}

.add-comment {
    display: flex;
    margin-top: 10px;
}

.add-comment input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
}

.add-comment button {
    border: none;
    background: none;
    cursor: pointer;
}

.deleteComment{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmation{
    position: relative;
    display: flex;
    column-gap: 10px;

    .deleteButton{
        font-size: 15px;
        color: red;
        cursor: pointer;
        font-weight: 500;
    }
}