.myStories-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1501;


  .myStories-login{
    z-index: 1502;
    cursor: pointer;
  }

  .myStories-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .myStories-user-info {
      display: flex;
      align-items: center;

      .myStories-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .myStories-user-details {
        margin-left: 10px;

        .myStories-login {
          font-weight: bold;
        }

        .myStories-time-ago {
          font-size: 0.9em;
          color: gray;
        }
      }
    }

    .myStories-close-btn {
      background: none;
      border: none;
      color: white;
      font-size: 1.5em;
      cursor: pointer;
    }
  }

  .myStories-progress-bar {
    display: flex;
    height: 5px;
    background: rgba(255, 255, 255, 0.1);

    .myStories-progress-segment {
      flex: 1;
      margin: 0 1px;
      background: rgba(255, 255, 255, 0.3);

      .myStories-progress-fill {
        height: 100%;
        transition: width 0.1s linear;
      }
    }
  }

  .myStories-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .myStories-media {
      max-width: 600px;
      width: 100%;
      max-height: 480px;
      height: 100%;
    }
  }

  .myStories-text {
    padding: 10px;
    font-size: 1.2em;
    text-align: center;
  }
}

.myStories-delete-confirmation {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  p {
    margin-bottom: 10px;
  }

  button {
    margin: 0 5px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &.myStories-confirm-btn {
      background-color: red;
      color: white;
    }

    &.myStories-cancel-btn {
      background-color: gray;
      color: white;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

/* Блок превью просмотров сторис (расположен в левом нижнем углу) */
.myStories-viewers-preview {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1502; // чтобы было поверх контента

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
    object-fit: cover;
  }

  span {
    font-size: 14px;
    color: white;
  }
}

/* Модальное окно с полным списком просмотров */
.myStories-viewers-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;

  .myStories-viewers-modal-content {
    background: #fff;
    border-radius: 8px;
    color: black;
    width: 90%;
    max-width: 500px;
    max-height: 80%;
    overflow-y: auto;
    padding: 20px;
    text-align: center;

    h2 {
      margin-top: 0;
      font-size: 1.2em;
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {        

        .myStories-viewers-modalContent-user{
          display: flex;
          align-items: center;
          gap: 10px;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
          object-fit: cover;
        }

        span {
          font-size: 16px;
          color: #333;
        }
      }
    }

    button {
      margin-top: 20px;
      padding: 10px 20px;
      font-size: 16px;
      background: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: #0056b3;
      }
    }
  }
}

.myStories-viewers-modal-content::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
