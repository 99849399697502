*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  max-width: 1440px;
  margin: 0 auto;
  background-color: #fff;
}
a{
  color: black;
  text-decoration: none;
}
li{
  list-style: none;
}

::-webkit-scrollbar {
  width: 0;
}