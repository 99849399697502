.story-upload {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1301;

  h2 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .error {
    color: #ff4d4d;
    text-align: center;
    margin-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    input[type="file"] {
      margin-bottom: 15px;
      width: 100%;
    }

    textarea {
      width: 100%;
      height: 80px;
      margin-bottom: 15px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      resize: none;
      font-size: 14px;
    }

    button {
      background-color: #3897f0;
      color: #fff;
      padding: 10px 15px;
      border: none;
      border-radius: 5px;
      font-weight: bold;
      cursor: pointer;
      width: 100%;
      font-size: 16px;

      &:hover {
        background-color: #3181d0;
      }
    }
  }

  .upload-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;

    .file-input-label,
    .camera-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #f0f0f0;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #e0e0e0;
      }

      .upload-icon,
      svg {
        font-size: 20px;
        color: #3897f0;
      }
    }
  }

  .preview {
    width: 100%;
    max-height: 300px;
    margin-bottom: 15px;
    border-radius: 5px;
    object-fit: cover;
  }

  video.preview {
    width: 100%;
    max-height: 300px;
  }

  .progress-bar {
    width: 100%;
    height: 8px;
    background-color: #f0f0f0;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 10px;

    .progress-bar-fill {
      height: 100%;
      background-color: #3897f0;
      width: 0;
      transition: width 0.3s ease;
    }
  }
}

/* Полупрозрачный фон */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
