.user-postPage {
    display: flex;
    flex-direction: column;
    width: 920px;
    margin-top: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

    .postPage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #EFEFEF;

        .postHeader-login{
            display: flex;
            align-items: center;
            gap: 10px;

            .postHeader-loginContainer{
                background-color: inherit;
                display: inline-block;
                img{
                    display: block;
                    width: 50px;
                    height: 50px;
                    background-color: #EFEFEF;
                    padding: 5px;
                    border-radius: 50%;
                }
            }
            span{
                font-size: 20px;
                font-weight: 500;
            }
        }

        .postHeader-nav {
            position: relative;

            img {
                cursor: pointer;
                padding: 10px;
                margin-right: 5px;
            }

            img:hover {
                background-color: rgb(199, 198, 198);
                border-radius: 10px;
            }

            ul {
                position: absolute;
                top: 100%; /* Спускаем меню ниже изображения */
                right: 0;  /* Выравниваем меню по правой стороне */
                background-color: #f3f3f3;
                padding: 10px;
                border: 1px solid #ddd;
                border-radius: 5px;
                flex-direction: column;
                justify-content: start;

                li {
                    color: black;
                    background-color: #EFEFEF;
                    padding: 20px;
                    cursor: pointer;
                }

                .deletePostButton {
                    color: red;
                }

                li:hover {
                    background-color: gray;
                }
            }
        }
    }

    .postPage-main {
        img {
            width: 920px;
            height: 600px;
            background-color: #000;
            object-fit: contain; /* 'contain' полностью поместит изображение в контейнер, но могут остаться пустые области */
            object-position: center;
        }

        video {
            width: 920px;
            height: 600px;
            background-color: #000;
            object-fit: contain; /* 'contain' полностью поместит изображение в контейнер, но могут остаться пустые области */
            object-position: center;
        }
    }

    .postPage-footer {
        padding: 0;
        background-color: #EFEFEF;

        .pageFooter-countsInf {
            display: flex;
            gap: 20px;
            align-items: center;

            nav {
                margin-top: 5px;

                span {
                    font-size: 12px;
                    font-weight: 400;
                }

                .buttonLike {
                    width: 30px;
                    height: 28px;
                }
            }

            img {
                width: 30px;
                height: 28px;
                cursor: pointer;
            }
        }

        .pageFooter-underText {
            margin-top: 5px;
            font-size: 16px;
            font-weight: 400;
            width: 600px;

            p {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.active-menu {
    display: block;
    z-index: 1030;
}

.unActive {
    display: none;
}

@media (max-width: 720px) {
    .user-postPage {
        width: 100%;

        .postPage-main {
            img, video {
                width: 100%;
                height: 100%;
                max-height: 500px;
            }
        }

        .postPage-footer {
            .pageFooter-underText {
                width: 100%;
            }
        }
    }
}
