.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: linear-gradient(145deg, #f0f0f0, #cfcfcf);
  border-radius: 25px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
}

.file-upload-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  color: #333;
  padding: 15px 25px;
  border-radius: 15px;
  cursor: pointer;
  border: 2px solid #ddd;
  width: 100%;
  max-width: 400px;
  text-align: center;
  gap: 12px;
  font-size: 18px;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.file-upload-label:hover {
  background-color: #e0e0e0;
  transform: scale(1.02);
}

.file-upload-label input[type="file"] {
  display: none;
}

.file-preview {
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.file-preview img,
.file-preview video {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.description-container {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 400px;
}

.description-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-bottom: 20px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.description-input:focus {
  border-color: #38f089;
}

.icon {
  color: #999;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #38f089;
}

.upload-button {
  background: linear-gradient(145deg, #38f089, #32d97c);
  padding: 12px 30px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}

.upload-button:hover {
  background-color: #32d97c;
  transform: scale(1.05);
}
