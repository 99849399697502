// src/components/notificationsPage.scss

.notifications-page {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;

    h1 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 24px;
        color: #000;
        font-weight: 600;
    }

    p {
        text-align: center;
        font-size: 16px;
        color: #999;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: flex;
        align-items: center;
        padding: 15px 10px;
        border-bottom: 1px solid #efefef;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #fafafa;
        }

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 15px;
        }
        .content_notification{
            display: flex;
            flex-direction: column;
            gap: 5px;

            span{
                display: flex;
                flex-direction: column;
            }
        }
    }
}
