.nav-Elements{
    
    .navUl{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 120px;
        margin-top: 10px;
        gap: 50px;
        font-size: 15px;
        font-weight: 400;
        transition: .7s;
        padding: 0px 5px;

        li{
            cursor: pointer;
        }
    }
}

@media(max-width: 480px){
    .nav-Elements{
        .navUl{
            display: grid;
            grid-template-columns: repeat(4, 2fr);
            width: 50px;
            gap: 15px;
        }
    }
}